import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  // baseURL: 'http://api.hyderabadcastingclub.com:8000',
  baseURL: 'https://api.hyderabadcastingclub.com',
  // baseURL: 'http://127.0.0.1:8000',
  // timeout: 5000, // Set the timeout for requests (optional)
});

// Add a request interceptor to attach the token from localStorage
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage
  if (token) {
    config.headers['Authorization'] = `Token ${token}`; // Add the token to the request headers
  }
  return config;
});

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response, // Simply return the response if it's successful
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/logout';
    }
    return Promise.reject(error); // Return the error to be handled by the calling code
  }
);

export default axiosInstance;
