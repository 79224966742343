import React, { useEffect, useState } from "react"
import "./Footer.css"
import axiosConfig from "../../Service/axiosConfig"
import { useNavigate, NavLink } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
const Footer = () => {
    const [loading, setLoading] = useState(false)

    const [Data, SetData] = useState([])
    const [Email, Setemail] = useState({})
    const [show, setShow] = useState(false);
    const [Error, setError] = useState("");
    const divideIntoColumns = (data, numColumns) => {
        const dividedData = [];
        const columnSize = Math.ceil(data.length / numColumns);

        for (let i = 0; i < data.length; i += columnSize) {
            dividedData.push(data.slice(i, i + columnSize));
        }

        return dividedData;
    };

    const renderColumns = (data) => {
        return data.map((column, index) => {
            return (
                <div className="col-lg-4 col-md-2 col-sm-2">
                    <ul className="footer-cat-text">
                        {column.map((item, itemIndex) => {
                            return (
                                <NavLink to={item.slug} ><li className="text-decoration-none" key={itemIndex}>{item.name}</li></NavLink>
                            )
                        })}
                    </ul>
                </div>
            )
        });
    };
    const HandleSubscribe = (e) => {
        const { name, value } = e.target
        Setemail({ ...Email, [name]: value })
    }
    const GetEmail = async () => {
        try {
            const response = await axiosConfig.post(`/masters/subscribe/`, Email)
            console.log(response)
            setError("Success: Your e-mail has been subscribed successfully!")

        }
        catch (error) {
            setShow(true)
            console.log(error.response.data.email[0])
            if (error.response.data.email[0] === "Enter a valid email address.") {
                setError("Invalid Email Address!")
            }
            if (error.response.data.email[0] === "subscribe with this email already exists.") {
                setError("Email already exists!")
            }
        }

    }
    const dividedColumns = divideIntoColumns(Data, 4);
    return (
        <>
            <div className="footer-container">
                <footer>
                    <div className="footer-links">
                        <div className="container-fluid">

                            <div className="inner">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="logo-footer"><a href="#"><img src={require("./images/HCC.png")} alt="" /></a></div>
                                        <div className="footer-content">
                                            <p className="description-footer"></p>
                                            <div className="address-footer">
                                                <p className="address-footer"><span>Address:</span>Sri Nagar Colony, Hyderabad, Telangana – 500016</p>
                                                Email:hyderabadcastingclub@gmail.com <br />
                                                Call us:+91 7799234563
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-content footer-information">
                                            <ul className="list-unstyled">
                                                <li className="address-footer">Information</li>
                                                <li><a href="/about-us">About Us</a></li>
                                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                                <li><a href="/refund-policy">Refund Policy</a></li>
                                                <li><a href="/terms-conditions">Terms &amp; Conditions</a></li>
                                                <li><a href="#/information-contact">Contact Us</a></li>
                                                <li><a href="#/information-sitemap">Site Map</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                    <ul className="list-unstyled">
                                        <li className="address-footer">Subscribe</li>
                                    </ul>
                                        <div className="d-flex">
                                            <input className="form-control" name="email" placeholder="Enter the email address" onChange={HandleSubscribe} required />
                                            <button type="button" className="btn btn-success btn-ft" onClick={GetEmail}>Subscribe</button>
                                        </div>
                                        <div className="color-error">
                                        {Error&&Error}
                                            </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-copyright text-center">
                            <p className="text-powered mb-0">Copyright &copy; 2023 <a href="https://hyderabadcastingclub.com/">hyderabadcastingclub.com</a>. All Rights Reserved. Developed and Powered by <a href="https://mhninformatics.com/" target="_blank">MHN INFORMATICS PRIVATE LIMITED</a></p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
export { Footer };
