import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { useAuth } from '../Utils/AuthContext';
import { Footer, Navbar, DeletePopupModal } from "../Components";
import { useNavigate, useSearchParams, NavLink, useParams } from "react-router-dom";
import CustomTop from "./CustomTop";
import DataTable from 'react-data-table-component';
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal"
import { MdOutlineArrowBackIos, MdOutlineLocalPhone, MdOutlineRemoveRedEye, MdOutlineTouchApp } from "react-icons/md";
import Select from 'react-select';
import { BiSearch } from "react-icons/bi";
import { LuSendHorizonal } from "react-icons/lu";
import { Card, Row, Col } from 'react-bootstrap';
const AuditionDetailPage = ({ }) => {
    let { id } = useParams()
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const [investment, setInvestment] = useState(25000);
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState("")
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(30);
    const [Data, SetData] = useState([])
    const [FilterData, SetFilterData] = useState({})
    const [requirementData, setRequirementData] = useState([])
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const handleInvestmentChange = (e) => {
        setInvestment(e.target.value);
    };
    const [AudiData, setAudiData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/`)
            console.log(responce.data)
            AuditionView()
            setAudiData(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const GetAuditionList = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`accounts/audition-applications/?page=${currentPage}&page_size=${RowPerPage}&profile=&audition=${id}&application_status=`)
            console.log(responce.data)
            setTotalRows(responce.data.count)
            const profiles = responce.data.results.map(item => {
                const profile = item.profile;
                profile.Status = `${item.application_status}`;
                return profile
            });
            console.log(profiles)
            SetData(profiles)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [closesIn, setClosesIn] = useState()
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const DeleteAlert = (id) => {
        if (localStorage.getItem('token')) {
            SetRowId(id)
            SetAlertDeletePopupType("success")
        } else {
            handleLoginShow();
        }
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const handleApply = (id) => {
        console.log(id)
        axiosConfig.post("/accounts/audition-applications/", { audition_requirement: id }).then(res => {
            console.log(res)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
            GetAuditionList();
        }).catch(error => {
            console.log(error)
        })
    }
    function getProjectType(status) {
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    }
    function getProjectTypeColor(status) {
        switch (status) {
            case 'Movies':
                return '#8b008b';
            case 'OTT':
                return '#696969';
            case 'TV_Serials':
                return '#8b4513';
            case 'Web_Series':
                return '#4682b4';
            case 'Music_Albums':
                return '#bdb76b';
            case 'Cover_Songs':
                return '#a52a2a';
            case 'Item_Song':
                return '#c71585';
            case 'Short_Films':
                return '#228b22';
            case 'Ads':
                return '#d2691e';
            case 'Modelling':
                return '#20b2aa';
            case 'Audition':
                return '#db7093';
            default:
                return '#ffffff';
        }
    }
    const AuditionView = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/viewed/`)
            console.log(responce)
        }
        catch (error) {
            console.log(error);
        }
    }
    const calculateDaysLeft = (endDate) => {
        const end = new Date(endDate);
        const now = new Date();
        const timeDiff = end - now;
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysLeft >= 0 ? `Closes in ${daysLeft} days` : "Closed";
    };
    // const calculateDaysLeft = (endDate) => {
    //     const today = new Date();
    //     const end = new Date(endDate);
    //     const differenceInTime = end.getTime() - today.getTime();
    //     const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    //     console.log(differenceInDays)
    //     setClosesIn(differenceInDays)
    // };
    const Navigate = useNavigate();
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            Navigate(`/profile/${link}`)
        }
        if (type === "back") {
            Navigate(`/auditions`)
        }
    }
    const [roles, setRoles] = useState([])
    const getRoles = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/role-24-frames/?page_size=5`)
            console.log(responce)
            setRoles(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'both', label: 'Both' },
        { value: 'other', label: 'Other' }
    ];
    const handleSelectChange = (selectedOption, name) => {
        SetFilterData({ ...FilterData, [name]: selectedOption?.value ? selectedOption?.value : "" });
    }
    useEffect(() => {
        GetData()
        getRoles()
        GetRequirementdata()
    }, [])
    useEffect(() => {
        GetAuditionList()
    }, [currentPage, RowPerPage]);
    const GetRequirementdata = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${id}`)
            console.log(responce)
            setRequirementData(responce.data.results);
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "image",
            selector: row => <div onClick={() => handleNavigate(row?.hcc_id, "profile")}>
                <img className="profile-pic" src={row.profile_pic && row.profile_pic} alt="profile pic" />
            </div>,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => <div onClick={() => handleNavigate(row?.hcc_id, "profile")}>{row.first_name}</div>,
            sortable: true
        },
        {
            name: "HCC ID",
            selector: "hcc_id",
            cell: row => row.hcc_id,
            sortable: true
        },
        {
            name: "Role",
            selector: "designation",
            cell: row => row.designation ? row.designation : "----",
            sortable: true
        },
        {
            name: "Age",
            selector: "dob",
            cell: row => <div>{calculateAge(row.dob)}</div>,
            sortable: true
        },
        {
            name: "Gender",
            selector: "gender",
            cell: row => <div>{row.gender}</div>,
            sortable: true
        },
        {
            name: "Location",
            selector: "location",
            cell: row => <div>{row?.present_state}-{row?.present_city}</div>,
            sortable: true
        },
        {
            name: "Status",
            selector: "application_status",
            cell: row => row.Status,
            sortable: true
        }
    ]
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    return (
        <>
            <CustomTop />
            <div className="search-screen-container">
                <Navbar />
                <div className="content-area">
                    <div className="investment-screen">
                        <div className="contents">
                            <div className="card left-panel">
                                <div className="d-flex justify-content-between mt-3">
                                    <div onClick={() => handleNavigate("", "back")} className="back-button"><MdOutlineArrowBackIos size={20} /> Back</div>
                                    <div className="back-button"><LuSendHorizonal size={25} /></div>
                                </div>
                                <div className='card-content'>
                                    <div className="d-flex align-items-center">
                                        <div className="line"></div>
                                        <div className="card-type" style={{ backgroundColor: getProjectTypeColor(AudiData.project_type) }}>{AudiData.project_type ? AudiData.project_type : "---"}</div>
                                    </div>
                                    <div className="card-styles">
                                        <div className='card-content'>
                                            <div className="card-descrt">
                                                <div className="card-descrt-inner">
                                                    {AudiData.banner_name ? (
                                                        <h4 className="banner-title">
                                                            {AudiData.banner_name}
                                                        </h4>
                                                    ) : (
                                                        <div style={{ padding: '20px' }}></div>
                                                    )}
                                                    <div className="card-audi">
                                                        {AudiData.platform &&
                                                            <div className="audi-label" style={{ background: '#b00de9' }}>{AudiData.platform}</div>
                                                        }
                                                        {AudiData.zoner &&
                                                            <div className="audi-label" style={{ background: '#671640' }}>{AudiData.zoner}</div>
                                                        }
                                                        {AudiData.loc_lang?.state &&
                                                            <div className="audi-label" style={{ background: '#8b008b' }}>{AudiData.loc_lang?.state}</div>
                                                        }
                                                        {AudiData.loc_lang?.city &&
                                                            <div className="audi-label" style={{ background: '#3cb371' }}>{AudiData.loc_lang?.city}</div>
                                                        }
                                                        {AudiData.loc_lang?.languages &&
                                                            <div className="audi-label" style={{ background: '#db7093' }}>{AudiData.loc_lang?.languages}</div>
                                                        }
                                                    </div>
                                                    <div className="card-detail">
                                                        <div style={{ fontWeight: "800", color: "#000", display: "flex", flexDirection: 'column', }}>
                                                            <div className="card-cont-title">Producer</div>
                                                            <div className='card-cont-detail'>{AudiData.producer_name ? AudiData.producer_name : "-"}</div>
                                                        </div>
                                                        <div style={{ fontWeight: "800", color: "#000", display: "flex", flexDirection: 'column', }}>
                                                            <div className="card-cont-title">Director</div>
                                                            <div className='card-cont-detail'>{AudiData.director_name ? AudiData.director_name : "-"}</div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            <div className="card-cont-title">Music Director</div>
                                                            <div className='card-cont-detail'>{AudiData.music_director ? AudiData.music_director : "-"}</div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            <div className="card-cont-title">Hero</div>
                                                            <div className='card-cont-detail'>{AudiData.hero ? AudiData.hero : "-"}</div>
                                                        </div>
                                                    </div>
                                                    <div className="auditionLists">
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            <div className="card-cont-title">Requirement</div>
                                                            <div className='card-cont-detail'>
                                                                {AudiData.description && AudiData.description.trim() !== "" ? AudiData.description : "No requirements available"}
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-bottom">
                                                    <div className="card-bottom-inner">
                                                        <div className="card-bottom-left card-botton-centent">
                                                            <div className="card-cont-title">Posted On</div>
                                                            <div className='card-cont-detail'>{AudiData.start_date ? AudiData.start_date : "-"}</div>
                                                        </div>
                                                        <div className="card-bottom-right-details card-botton-centent">
                                                            <div className="card-cont-title">Posted By</div>
                                                            {AudiData.profile?.first_name && <div className='card-cont-detail' onClick={() => handleNavigate(AudiData.profile?.hcc_id, "profile")}>
                                                                {AudiData.profile.first_name}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                            <div className="right-panel">
                                <div className="investment-box">
                                    <div>
                                        <label>{AudiData.project_type} Budget</label>
                                        <input type="number" value={AudiData.budget ? AudiData.budget : 0}
                                            // onChange={handleInvestmentChange} 
                                            disabled />
                                    </div>
                                    <div>
                                        <div className="card-botton-centent">
                                            <div className="card-cont-title">Closes On</div>
                                            <div className='card-cont-detail'>{AudiData.end_date ? AudiData.end_date : "-"}</div>
                                            {/* <div>{calculateDaysLeft(AudiData.end_date)}</div> */}
                                        </div>
                                        <div className="card-actions">
                                            <div className="card-botton-centent">
                                                <div className="card-cont-title">Applications</div>
                                                <div className='card-cont-detail'>{AudiData.applications ? AudiData.applications : "-"}</div>
                                            </div>
                                            <div className="card-botton-centent">
                                                <div className="card-cont-title">Views<MdOutlineRemoveRedEye /></div>
                                                <div className='card-cont-detail'>{AudiData.views}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="card-cont-title">Contact Info:</div>
                                        {AudiData.contact_name && <div className='card-cont-detail'>{AudiData.contact_name}({AudiData.contact_designation})</div>}
                                        <div className="d-flex justify-content-between">
                                            <button className="btn btn-secondary">
                                                <a href={`tel:+${AudiData?.contact_mobile_no}`}><MdOutlineLocalPhone size={20} /> Contact Now</a>
                                            </button>
                                            {/* <button type="button" className="btn btn-primary" onClick={() => DeleteAlert(AudiData.id)}><MdOutlineTouchApp />Apply</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="investment-screen">
                        <Row>
                            {requirementData.map((requirement, index) => (
                                <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>{requirement.character}</Card.Title>
                                            <Card.Text>
                                                <strong>Audition ID:</strong> {requirement.audition} <br />
                                                <strong>Gender:</strong> {requirement.gender} <br />
                                                <strong>Age Range:</strong> {requirement.from_age} - {requirement.to_age} years <br />
                                                <strong>No. of Requirements:</strong> {requirement.no_of_requirements} <br />
                                                <strong>Languages:</strong> {requirement.languages.join(', ')} <br />
                                                <strong>State:</strong> {requirement.state.join(', ')} <br />
                                                <strong>Created At:</strong> {new Date(requirement.created_at).toLocaleDateString()}
                                                <strong>Specification:</strong> {requirement.specification} <br />
                                                <button type="button" className="btn btn-primary" onClick={() => DeleteAlert(requirement.id)}><MdOutlineTouchApp />Apply</button>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="investment-screen">
                        {Data.length > 0 && AudiData?.profile?.id === JSON.parse(localStorage.getItem('profile_data'))?.profile_id &&
                            <>
                                <div><b>Received Applications</b> ({AudiData.applications})</div>
                                <div className="row">
                                    <Select
                                        className="col-4"
                                        options={genderOptions}
                                        placeholder="Gender"
                                        value={genderOptions.find(option => option.value === Data.gender)}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "gender")}
                                        required
                                    />
                                    <Select
                                        className="col-4"

                                        options={roles.map((res) => ({ "value": res.id, "label": res.frame_name }))}
                                        placeholder="Select Role"
                                        value={roles.map(res => ({ "value": res.id, "label": res.frame_name })).find(res => res.value === Data.role)}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "role")}
                                        required
                                    />
                                    <div className="form-control d-flex align-items-center w-25">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            autoFocus
                                        />
                                        <div className="search-icon1">
                                            <BiSearch size={20} />
                                        </div>
                                    </div>
                                </div>
                                {/* <table className="table table-bordered">
                                    <div className="">
                                        <thead>
                                            <tr>
                                                <th>Sr</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Role</th>
                                                <th>Age</th>
                                                <th>Gender</th>
                                                <th>Location</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {console.log(Data)}
                                            {Data.map((frame, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td onClick={() => handleNavigate(frame?.hcc_id, "profile")}>
                                                            <img src={frame?.profile_pic} alt="Profile Pic" class="profile-pic"></img>
                                                        </td>
                                                        <td onClick={() => handleNavigate(frame?.hcc_id, "profile")}>{frame?.first_name}</td>
                                                        <td>{frame?.designation}</td>
                                                        <td>{frame?.dob}</td>
                                                        <td>{frame?.gender}</td>
                                                        <td>{frame?.present_state}-{frame?.present_city}</td>
                                                        <td>{frame?.Status}</td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </div>
                                </table> */}
                                <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                    paginationPerPage={RowPerPage}
                                    paginationDefaultPage={currentPage}
                                    onChangePage={(page) => setCurrentPage(page)}
                                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                    // onSort={handleSort}
                                    paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                    progressComponent={<div className="loader-container">
                                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                    </div>}
                                />
                            </>
                        }

                        {/* <ExploreGrid Data={Data} loading={loading} loading2={loading2} /> */}
                    </div>
                </div>
            </div >
            <Footer />
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Applied for Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={handleApply} CancelAlerrt={CancelAlerrt} row={RowId} message={"Are You Sure You Want Apply for this Audition ?"} />
            }
        </>
    )
}
export { AuditionDetailPage };