import React, { useState, useEffect, useCallback } from "react";
import "../Components/Navbar/Navbar.css"
import axiosConfig from "../Service/axiosConfig"
import { useSelector } from "react-redux";
import { selectCartTotal } from "../redux/Slices/cartSlice";
import { MdOutlineNotifications, MdOutlineMovieCreation, MdOutlineArrowBack } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { BiSearch, BiAddToQueue, BiFilterAlt } from "react-icons/bi";
import { RiAccountCircleLine } from "react-icons/ri";
import { AiOutlineTeam, AiOutlineStar } from "react-icons/ai";
import { LuMessageSquare } from "react-icons/lu";
import { NavLink, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { AddressModal } from "../Components/PopupModal";
import { PostDP, Navbar } from "../Components";
import { FormParser } from "../Components";
import Masters from "../Components/masters.json"
import { useAuth } from '../Utils/AuthContext';
import { Login, Signup } from "../Components/Login";
import CustomMobile from "./CustomMobile";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"
import { ExploreGrid } from "./exploregrid";
import { NewPost } from "./NewPost";
import { Notifications } from "./Notifications";
const SearchProducts = () => {
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams, "searchParams")
    const search = searchParams.get("query");
    const Navigate = useNavigate();
    const total = useSelector(selectCartTotal);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const [query, setQuery] = useState(search);
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [popupType, setPopupType] = useState("")
    const [Data, setData] = useState([])
    const [loading, setLoading] = useState("")
    const [loading2, setLoading2] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [parmsData, setparmsData] = useState("")
    const [ProfileData, setProfileData] = useState({})
    const [filterShow, setFilterShow] = useState(false)
    const [totalPages, settotalPages] = useState()
    const [initialformData, setinitialFormData] = useState({});
    const [isRead, setIsRead] = useState(false);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(false);
    const [AddPost, setAddPost] = useState(false)
    const handleClose = () => { setShow(false); setPages(1) }
    const handleShow = () => { setShow(true); setPages(1) }
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const formSubmit = (Data) => {
        setFilterShow(false)
        let queryStringParts = [];
        Object.keys(Data).forEach(key => {
            const value = Data[key];
            if (Array.isArray(value)) {
                value.forEach(arrayValue => {
                    queryStringParts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(arrayValue)}`);
                });
            } else {
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        });
        const queryString = queryStringParts.join('&');
        setSearchParams(queryString);

        console.log(Data);
        axiosConfig.get(`/accounts/user-profiles/?page_size=20&page=${page}&${queryString}`)
            .then(response => {
                settotalPages(Math.ceil(response.data.count / 20));
                setData(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                console.log(error.response.data.error);
            });
    };


    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        console.log("test")
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    let page = 1;

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const handleInputChange = async (event) => {
        try {
            const responce = await axiosConfig.get(`/accounts/user-profiles/?search_query=${event.target.value}&page_size=20&page=${page}`, {
                params: parmsData
            });
            settotalPages(Math.ceil(responce.data.count / 20))
            setData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const debouncedSearch = useCallback(debounce(handleInputChange, 500), []);
    const handleInputChangeDebounced = (event) => {
        setQuery(event.target.value);
        setSearchParams({ query: event.target.value })
        setLoading(true);
        debouncedSearch(event);
    };
    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                if (page <= totalPages && !loading2) {
                    setLoading2(true);
                    page = page + 1;
                    let url = `/accounts/user-profiles/?page_size=20&page=${page}`;
                    if (search !== null) {
                        url += `&search_query=${search}`;
                    }
                    const resp = await axiosConfig.get(url, {
                        params: searchParams
                    });
                    setData((Data) => [...Data, ...resp.data.results]);
                    setLoading2(false);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading2(false);
        }
    };

    const GetData = async () => {
        try {
            axiosConfig.get(`/accounts/user-profiles/?page_size=20&page=${page}&${searchParams}`)
                .then(response => {
                    settotalPages(Math.ceil(response.data.count / 20));
                    setData(response.data.results);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error.response.data.error);
                });
        }
        catch (error) {
            console.log(error)
        }
    }

    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }

    const loadFormDataFromURL = () => {
        setLoading(true);
        let paramsData = {};
        for (let [key, value] of searchParams) {
            paramsData[key] = value;
        }
        console.log("paramsData")
        console.log(paramsData)
        setinitialFormData(paramsData);
        setLoading(false);

    };

    useEffect(() => {
        GetData();
        setProfile();
        loadFormDataFromURL();
    }, [])
    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [totalPages])

    return (
        <>
            <div className="desktop-container">
                <div className="top-main">
                    <div className="logo" onClick={() => Navigate('/')}>
                        <img src={require('../Pages/images/HCC (10).png')} className="logo-image" />
                    </div>
                    <div className="search">
                        {/* <BiFilterAlt className="option-icon filter-icons hide-for-desktop" onClick={() => setFilterShow(true)} /> */}
                        <input
                            type="text"
                            placeholder="Search for Artists..."
                            value={query}
                            onChange={handleInputChangeDebounced}
                            autoFocus
                        />
                        <BiSearch className="option-icon" />
                    </div>
                    {isLoggedIn ?
                        <div className="options">
                            <div className="options-container">
                                <div onClick={() => Navigate('/')}><LuMessageSquare className="option-icon" /></div>
                                <div onClick={handleShow}><MdOutlineNotifications className="option-icon" /></div>
                                {is24Craft &&
                                    <>
                                        <div onClick={(e) => { setAddPost(true); }}><BiAddToQueue className="option-icon" /></div>
                                    </>
                                }
                                <div className="nav-item dropdown account-topnav">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={ProfileData && ProfileData.profile_pic} width="40" className="profile-pic-top" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to="/my-profile" >My Account</NavLink>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to="/my-profile" >Change Password</NavLink>
                                            </a>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><NavLink to="/logout" className="dropdown-item" onClick={() => ActiveData("/logout")}>Log Out</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="options">
                            <div className="login-text" onClick={handleLoginShow}>login</div>
                        </div>
                    }
                </div>
            </div>
            <div className="top-mobile-main">
                <div className="search-bar-filter">
                    <MdOutlineArrowBack className="option-icon" onClick={() => Navigate('/')} />
                    <div className="search-mobile" style={{ height: '30px', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search for Artists..."
                            value={query}
                            onChange={handleInputChangeDebounced}
                            autoFocus
                        />
                        <BiSearch size={20} />
                    </div>
                    <HiOutlineAdjustmentsHorizontal className="option-icon" onClick={() => setFilterShow(true)} />
                </div>
                <CustomMobile />
            </div>
            <div className="search-screen-container">
                <Navbar />
                <div className="filters-container hide-for-mobile">
                    <FormParser modelObject={Masters.FilterForm} formData={initialformData} formSubmit={formSubmit} />
                </div>
                <div className="content-area">
                    <ExploreGrid Data={Data} loading={loading} loading2={loading2} />
                </div>
            </div>

            <Modal
                show={filterShow}
                onHide={() => setFilterShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                className="mobile-filter-pop"
            >
                <Modal.Body className="">
                    <button variant="primary" className="btn btn-default close-btn" onClick={() => setFilterShow(false)}>
                        X
                    </button>
                    <FormParser modelObject={Masters.FilterForm} formData={initialformData} formSubmit={formSubmit} />
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton>
                    <Modal.Title>{loginView == "login" ? "Login" : "Signup"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loginView == "login" ? (
                        <>
                            <Login handleLoginEvent={handleLoginEvent} />
                            <a href="#" className="switchtag" onClick={() => setloginView("signup")} >Don't have an account ? Click to signup</a>
                        </>
                    ) : (
                        <>
                            <Signup handleLoginEvent={handleLoginEvent} />
                            <a href="#" className="switchtag" onClick={() => setloginView("login")} >Already have an account ? Click to Login</a>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="loginfooter">
                    <p>By continuing, you agree to our</p>
                    <a href="#" target="_blank">Terms of service</a>
                    <a href="#" target="_blank">Privacy policy</a>
                </Modal.Footer>
            </Modal>
            {popupType === "success" &&
                <AddressModal popupType={popupType} setPopupType={setPopupType} />
            }
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
            <Notifications show={show} setShow={setShow} handleClose={handleClose} ProfileData={ProfileData} isRead={isRead} setIsRead={setIsRead} setPage={setPages} page={pages} />
        </>
    )
}
export { SearchProducts };