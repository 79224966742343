import React, { useEffect, useState } from "react";
import axiosConfig from "../Service/axiosConfig"
import { PopupModal } from "../Components";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormParser } from '../Components/FormParser/FormParser';
import Masters from "../Components/masters.json"
import { AuditionRequirements } from "./AuditionRequirements";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
const AuditionModal = ({ handleClose }) => {
    let { id } = useParams()
    console.log(id)
    const [key, setKey] = useState('Audition Info');
    const [audiShow, setAudiShow] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [Error, SetError] = useState("");
    const [loading, setLoading] = useState(false);
    const [AudiData, setAudiData] = useState([]);
    const [AudiId, SetAudiId] = useState(id || null);
    const [requirement, setRequirement] = useState(false)
    const [requirementData, setRequirementData] = useState([])
    const [requirementShow, setRequirementShow] = useState(false)
    const [step, setStep] = useState(0)
    const [Audistatus, setAudiStatus] = useState(false);
    // const formSubmit = (Data) => {
    //     SetError("")
    //     console.log(Data)
    //     axiosConfig.post("/accounts/auditions/", Data).then(res => {
    //         console.log(res)
    //         setpopupType("success");
    //         setStep(1)
    //         SetAudiId(res.data.id)
    //         handleRequirements()
    //     }).catch(error => {
    //         console.log(error.response.data)
    //         setAudiShow(true)
    //         if (error.response.data.contact_mobile_no.includes("Invalid Number")) {
    //             SetError("Contact person Mobile Number Consists of ten digits");
    //             setpopupType("error")
    //         }
    //         if (error.response.data.director_mobile_no.includes("Invalid Number")) {
    //             SetError("Director Mobile Number Consists of ten digits");
    //             setpopupType("error")
    //         }
    //         if (error.response.data.producer_mobile_no.includes("Invalid Number")) {
    //             SetError("Producer Mobile Number Consists of ten digits");
    //             setpopupType("error")
    //         }
    //         setpopupType("error")
    //     })
    // }
    const formSubmit = (Data) => {
        SetError("");
        if (AudiId) {
            axiosConfig.patch(`/accounts/auditions/${AudiId}/`, Data).then(res => {
                console.log(res);
                setpopupType("success");
                handleRequirements();
            }).catch(handleError);
        } else {
            axiosConfig.post("/accounts/auditions/", Data).then(res => {
                console.log(res);
                setpopupType("success");
                SetAudiId(res.data.id);
                handleRequirements();
            }).catch(handleError);
        }
    };

    const handleError = (error) => {
        console.log(error.response.data);
        setAudiShow(true);
        if (error.response.data.contact_mobile_no.includes("Invalid Number")) {
            SetError("Contact person Mobile Number Consists of ten digits");
        }
        if (error.response.data.director_mobile_no.includes("Invalid Number")) {
            SetError("Director Mobile Number Consists of ten digits");
        }
        if (error.response.data.producer_mobile_no.includes("Invalid Number")) {
            SetError("Producer Mobile Number Consists of ten digits");
        }
        setpopupType("error");
    };
    const handleRequirements = () => {
        console.log(AudiId, "AudiId")
        setAudiShow(false)
        setRequirement(true)
    }
    const AudiRequirements = (data) => {
        console.log(data, "AudiId", AudiId)
        const orderTopost = {
            ...data,
            audition: AudiId
        }
        console.log(orderTopost)
        axiosConfig.post("/accounts/audition-requirements/", orderTopost).then(res => {
            console.log(res)
            setStep(2)
            GetRequirementdata()
            setRequirement(true)
            setRequirementShow(false)
        }).catch(error => {
            console.log(error.response.data)
            setpopupType("error");
        })
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${AudiId}/`)
            console.log(responce)
            setAudiData(responce.data);
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    console.log("AudiData", requirementData)
    const GetRequirementdata = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${AudiId}`)
            console.log(responce)
            setRequirementData(responce.data.results);
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    const handleStatusShow = () => {
        setRequirement(false)
        setAudiStatus(true)
        GetData()
    }
    const navigate = useNavigate();
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "back") {
            navigate(`/Auditions/`)
        }
    }

    useEffect(() => {
        if (AudiId) {
            GetData();
            GetRequirementdata()
        }
    }, [AudiId])
    return (
        <>
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} error={Error} />
            }
            <div className="audi-modal-content">
                <div>
                    <div className="client-options">
                        <BiArrowBack onClick={() => handleNavigate("", "back")} className="option-icon" />
                    </div>
                    {AudiId &&
                        <div className="client-info">
                            <div className="contact-detile">
                                Banner Name : <div className="data-text">{AudiData.banner_name}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Name : <div className="data-text">{AudiData.contact_name}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Designation Name : <div className="data-text">{AudiData.contact_designation}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Mobile No : <a href={`tel:+${AudiData.contact_mobile_no}`} className="data-text">{AudiData.contact_mobile_no}</a>
                            </div>
                        </div>
                    }

                </div>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="Audition Info" title="Audition Info">
                        <FormParser
                            modelObject={Masters.Audition}
                            formSubmit={formSubmit}
                            error={Error}
                            buttonTitle={id ? "Update & Add Requirements" : "Save & Add Requirements"}
                            formData={AudiData} />
                    </Tab>
                    <Tab eventKey="Requirements" title="Requirements">
                        <>
                            <AuditionRequirements requirementData={requirementData} />
                        </>
                    </Tab>
                    <Tab eventKey="Status" title="Status">
                        <>
                            {console.log(AudiData)}
                            <>
                                <div className="d-flex">
                                    <div>Audition Status : </div>
                                    <h5> {AudiData.approval_status ? AudiData.approval_status : ""}</h5>
                                </div>
                                <div className="d-flex">
                                    <div>Comments : </div>
                                    {/* <textarea className="form-control" rows="2" placeholder="Comments" value={AudiData.approval_message}></textarea> */}
                                    <h5> {AudiData.approval_message ? AudiData.approval_message : "--"}</h5>
                                </div>
                            </>

                            {/* <button className="btn btn-primary" onClick={handleClose}>Close Modal</button> */}
                        </>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}
export { AuditionModal };