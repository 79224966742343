import React, { useState, useEffect } from "react";
import { Topbar, Navbar,Categories,AdRight,AdPost,Footer,Stories } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPageGrid } from "./PostsPageGrid";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";

const ProfileAbout = () => {
    const {hcc_id} = useParams();
    console.log('hcc_id',hcc_id)
    const [loading, setLoading] = useState(false)

    const [ProfileData, SetProfileData] = useState({})

    const GetData = async () => {
        setLoading(true)

        try {
            const responce = await axiosConfig.get(`/accounts/user-profiles/?hcc_id=${hcc_id}`)
            console.log(responce)
            SetProfileData(responce.data.results[0])
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)

        }

    }
    useEffect(() => {
        GetData();
    }, [])

    return (
        <>
        <Topbar />
        <div className="main-container">
            <Navbar />

                <div className="content-area">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('./images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <div>

                        <div className="profile-header">
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={ProfileData.profile_pic} className="dp" />
                                </div>
                                <div className="col-md-8">
                                    <div className="profile-info">
                                        <p>{ProfileData.full_name}</p>
                                        <button className="btn btn-default btn-follow">Follow</button>
                                        <button className="btn btn-default btn-message">Message</button>
                                    </div>
                                    <div className="followers-info">
                                        <p><b>10</b> Gallery</p>
                                        <p><b>{ProfileData.followers}</b> Followers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stories-container">
                            <Stories />
                        </div>
                        <div className="profile-tablinks">
                            <NavLink to={`/profile/${hcc_id}`}><p>Gallery</p></NavLink>
                            <NavLink to={`/profile/${hcc_id}/about`}><p>About</p></NavLink>
                            <NavLink to={`/profile/${hcc_id}/projects`}><p>Projects</p></NavLink>
                        </div>
                        <div className="about-container">
                            <p>Biodata</p>
                        </div>
                    </div>
  )}
                </div>
                
            </div>
            {/* <div className="info-banner">
                <div className="info-banner-container">
                    <p>Delivery In 10 Mins</p>
                </div>
            </div> */}

            <Footer />
        </>
    )

}
export {ProfileAbout}