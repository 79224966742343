import React, { useEffect, useState } from "react";
import { Navbar } from "../Components";
import { NavLink,useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useSelector, useDispatch } from 'react-redux';
import { addProduct, selectCartProducts, selectCartTotal, removeProduct,clearCart } from "../redux/Slices/cartSlice"
import axiosConfig from "../Service/axiosConfig"
const Checkout = () => {
    const [PaymentMethod, setPeyMethod] = useState("")
    const products = useSelector(selectCartProducts);
    const total = useSelector(selectCartTotal);
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const[Address,setAddress]=useState({})
    const[Orders,setorders]=useState({})
    let Navigate=useNavigate()
    const dispatch = useDispatch();
    const loadScript = () => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
            document.body.appendChild(script);
        };

    const handlePaymentSuccess = async (response) => {
        try {
            let bodyData = new FormData();

            // we will send the response we've got from razorpay to the backend to validate the payment
            bodyData.append("response", JSON.stringify(response));

            await axiosConfig.post(`/accounts/payment/success/`, bodyData)
                .then(async(res) => {
                    console.log("Everything is OK!");
                    console.log(res)
                    setName("");
                    setAmount("");
                    Navigate("/account/orders")
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(console.error());
        }
    };
    const showRazorpay = async () => {
        const responce = await loadScript();
        let bodyData = new FormData();
        let Order=localStorage.getItem("orderId")
        var convertedValue = parseFloat(Order);
        bodyData.append("order_id",convertedValue );
        const data = await axiosConfig.post("/accounts/pay/", bodyData,).then((res) => {
            return res;
        }).catch((error) => {
            console.log(error)
        });
        var options = {
            // key_id: process.env.REACT_APP_PUBLIC_KEY, // in react your environment variable must start with REACT_APP_
            // key_secret: process.env.REACT_APP_SECRET_KEY,
            amount: data.data.payment.amount,
            currency: "INR",
            name: "TO OPOTO STORE",
            description: "Test teansaction",
            image: "", // add image url
            order_id: data.data.payment.id,
            handler: function (response) {
                // we will handle success by calling handlePaymentSuccess method and
                // will pass the response that we've got from razorpay
                handlePaymentSuccess(response);
            },
            prefill: {
                name: localStorage.getItem("name"),
                email: "User's email",
                contact: localStorage.getItem("number"),
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    const GetAddress = async (id) => {
        try {
            const response = await axiosConfig.get(`/accounts/address/${localStorage.getItem("address_id")}/`)
            setAddress(response.data)

        }
        catch (error) {
            console.log(error)
        }

    }
    const GetOrders=async()=>{
        await axiosConfig.get(`/accounts/orders/${localStorage.getItem("orderId")}/`).then(res=>{
            console.log(res)
            setorders(res.data)
           }).catch(error=>{
            console.log(error)
           })
    }
   const NavigateOrders=async()=>{
    await axiosConfig.put(`/accounts/orders/${localStorage.getItem("orderId")}/`,{...Orders,orderstatus:"Confirmed"}).then(res=>{
        console.log(res)
        localStorage.removeItem("orderId")
        localStorage.removeItem("state")
        localStorage.removeItem("total")
        dispatch(clearCart());
        Navigate("/account/orders")
       }).catch(error=>{
        console.log(error)
       })
   }
    console.log(Address,Orders)
    useEffect(()=>{
       GetAddress()
       GetOrders()
    },[])
    return (
        <>
        <div className="navbar-container">
                <nav className="navbar fixed-top navbar-expand-lg">
                    <div className="container-fluid">
                        <NavLink to="/" className="navbar-brand" >
                            <img src={require("../Components/Navbar/images/logo.png")} alt="logo" />
                        </NavLink>
                        </div>
                        </nav>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-9 payment-main">
                        <div className="payments-heading">
                            Select Payment Method
                        </div>
                        <div className="checkout-left-payment">
                            <div className="my-addresses">
                                <button type="button" className={PaymentMethod === "Cash" ? "btn btn--inverted my-addresses__new-address-btn-checkout" : "btn btn--inverted my-addresses__new-address-btn"} onClick={() => setPeyMethod("Cash")}>Cash</button>
                            </div>
                            <div className="my-addresses">
                                <button type="button" className={PaymentMethod === "Online Payment" ? "btn btn--inverted my-addresses__new-address-btn-checkout" : "btn btn--inverted my-addresses__new-address-btn"} onClick={() => setPeyMethod("Online Payment")}> Online Payment</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 payment-main checkout-cart">
                        <div className="checkout-step--address">
                            <p className="checkout-step-name">Delivery Address</p>
                            <div className="selected-address">
                                <span className="save-address-ass">{Address.adress_type}: </span>
                                {Address.address}, {Address.landmark}, {Address.flat_no},{Address.pincode} Telangana, India
                            </div>
                        </div>
                        <Card style={{ width: '100%' }}>
                            <Card.Header>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="checkot-cart-title">My Cart</p>
                                    <p className="checkot-cart-title">{products.length} items</p>
                                </div>
                            </Card.Header>
                            {
                                products.map((card, i) => {
                                    console.log(card)
                                    return (
                                        <ListGroup key={i} variant="flush">
                                            <ListGroup.Item>
                                                <div className="checkout-card-main">
                                                    <p className="checkout-qty">{card.quantity}</p>
                                                    <div className="checkout-img-main">
                                                        <img src={card.image} className="checkout-img" />
                                                    </div>
                                                        <div className="checkout-cart-itme-name">{card.name}
                                                          <p>{card.weight_in_kg}</p>
                                                        <p>{card.offer_price > 0 ? card.offer_price : card.price}</p>
                                                        </div>
                                                    </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    )
                                })
                            }
                        </Card>
                        <button type="button" className={PaymentMethod ? "btn-lg btn-block button-checkout" : "btn-lg btn-block button-checkout-disbled"} disabled={PaymentMethod === "" ? true : false} onClick={PaymentMethod === "Cash" ? NavigateOrders :showRazorpay}>{PaymentMethod === "Online Payment" ? "Pay Now" : "Confirm"}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export { Checkout }