import React from "react"
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { StaffLogin, DashboardLayout, FriendlyUrlComponent } from "./Components"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./Utils/ProtectedRoute"
import {
  Home, Dashboard, ProductSearch, View24Crafts, ViewCraft, ViewCraftEdit, SubCategoriesPage, NotFound, MyOrders,
  MyOrderDetails, MyAddress, Wallet, SearchProducts, Checkout, ProfilePage, NewPost, ProfileAbout, Explore,
  SharedPostPage, ShortlistPage, AboutPage, TermConditions, PrivacyPolicy, VerifiedPage,
  AuditionDetailPage,
  RefundPolicy,
  AuditionModal
} from "./Pages"
import Logout from "./Utils/Logout";
import { AccountResp } from "./Components/Navbar";
import { AuthProvider } from './Utils/AuthContext';
import CustomTop from "./Pages/CustomTop";
import { AuditionPage } from "./Pages/AuditionPage";
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/signup' element={<Home />} />
          <Route path="/logout" element={<Logout />} />
          <Route path='/profile/:hcc_id' element={<ProfilePage />} />
          <Route path='/p/:uid' element={<SharedPostPage />} />
          <Route path='/profile/:hcc_id/about' element={<ProfileAbout />} />
          <Route path='/product-search' element={<ProductSearch />} />
          <Route path='/s' element={<SearchProducts />} />
          <Route path="/shortlist" element={<ShortlistPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/terms-conditions" element={<TermConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path='/explore' element={<Explore />} />
          <Route path='/c/:categoryslug/:subcategoryslug' element={<SubCategoriesPage />} />
          <Route path='/:friendlyurl' element={<FriendlyUrlComponent />} />
          <Route path='/:categoryurl/:friendlyurl' element={<FriendlyUrlComponent />} />
          <Route path='/:subcategoryurl/:categoryurl/:friendlyurl' element={<FriendlyUrlComponent />} />
          <Route path="/auditions" element={<AuditionPage />} />
          <Route path="/auditions/add" element={<AuditionModal />} />
          <Route path="/auditions/:id/edit" element={<AuditionModal />} />
          <Route path="/auditions/:id" element={<AuditionDetailPage />} />
          <Route path="" element={<ProtectedRoute />} >
            <Route path='/my-profile' element={<ProfilePage />} />
            <Route path='/24crafts' element={<View24Crafts />} />
            <Route path='/new-post' element={<NewPost />} />
            <Route path="/request-verifications" element={<VerifiedPage />} />
            <Route path='/24crafts/:craftname/:craftslug' element={<ViewCraft />} />
            <Route path='/24crafts/edit/:craftname/:craftslug' element={<ViewCraftEdit />} />
            <Route path='/account' element={<AccountResp />} />
            <Route path='/account/orders' element={<MyOrders />} />
            <Route path='/account/orders/:orderId/:address' element={<MyOrderDetails />} />
            <Route path='/account/addresses' element={<MyAddress />} />
            <Route path='/account/wallet' element={<Wallet />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path='*' element={<NotFound />} />
          <Route path='/customtop' element={<CustomTop />} />
        </Routes>
      </Router>
    </AuthProvider>

  );
}

export default App;
