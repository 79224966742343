import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineComment, AiOutlineSend, AiOutlineStar } from "react-icons/ai";
import { LiaUserCheckSolid } from "react-icons/lia";
import { Like } from "../Like"
import { Actions } from "./Actions";
import { LikesPopup } from "./LikesPop";
import { Shortlist } from "../ShortList/ShortList";
import { PostPop } from "./PostPop";
import { Modal } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";

const PostFooter = (props) => {
    const { item } = props;
    console.log(item)
    const [loading, setLoading] = useState(false)
    const [likesCount, SetlikesCount] = useState(item.likes)
    const [isHidden, setIsHidden] = useState(false);
    const [smShow, setSmShow] = useState(false);
    const [commentOpen, setCommentsOpen] = useState(false)
    const [chatMessage, setChatMessage] = useState("");
    function updateLikesCount(action) {
        console.log("sss", action, likesCount)
        if (action === "like") {
            SetlikesCount(likesCount + 1)
        } else {
            SetlikesCount(likesCount - 1)
        }
    }
    console.log(item)
    function handleHideEvent() {
        setIsHidden(true);
    }
    if (isHidden) {
        return null;
    }
    const shareContent = async (share_to) => {
        try {
            let text = `${window.location.origin}/p/${item.uid}`
            const shareUrl = encodeURIComponent(text);

            if (share_to == "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to == "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    // Use Web Share API if available
                    await navigator.share({
                        title: 'Share Post',
                        text: `${window.location.origin}/p/${item.uid}`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const OpenPopUp = () => {
        setSmShow(true)
    }
    const commentsOpen = () => {
        if (localStorage.getItem('token')) {
            setCommentsOpen(true)
        } else {
            handleLoginShow();
        }
    }
    const commentsClose = () => {
        setCommentsOpen(false)
    }
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const handleSendMessage = () => {
        console.log(chatMessage, "messagess")
        const profile_data = JSON.parse(localStorage.getItem("profile_data"))
        const data_to_post = {
            content: chatMessage,
            modelimage: item.id,
            user: profile_data?.id
        }
        if (localStorage.getItem('token')) {
            axiosConfig.post(`/accounts/comments/`, data_to_post)
                .then(response => {
                    console.log(response)
                    setChatMessage("")
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            handleLoginShow();
        }
    };
    return (
        <>
            <div className="post-footer-container">
                <div className="post-footer">
                    <div className="symbols">
                        <Like productId={item.id} updateLikesCount={updateLikesCount} />
                        <AiOutlineComment className="post-symbol-icons" onClick={commentsOpen} />
                        <AiOutlineSend className="post-symbol-icons" onClick={() => shareContent("share")} />
                    </div>
                    <div className="shortlist">
                        {/* <LiaUserCheckSolid className="post-symbol-icons" /> */}
                        <Shortlist ShortlistId={item.profile.id} />
                    </div>
                </div>
                <div className="post-likes" onClick={OpenPopUp}>
                    {likesCount > 0 && <p>{likesCount} {likesCount === 1 ? 'like' : 'likes'}</p>}
                </div>
                <div className="post-caption">
                    <p><strong>{item.profile.full_name} : </strong> {item.caption}</p>
                </div>
                <div className="post-comments">
                    <p className="open-comments" onClick={commentsOpen}>View all {item.comments} {item.comments === 1 ? "comment" : "comments"} </p>
                </div>
                <div className="post-new-comment">
                    <input type="text"
                        className="form-control msg-text"
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                        placeholder="Type a message..."
                    />
                    {chatMessage &&
                        <div className="send-button" onClick={handleSendMessage}>
                            Send
                        </div>
                    }
                </div>
            </div>
            {smShow === true &&
                <LikesPopup item={item} smShow={smShow} setSmShow={setSmShow} />
            }
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={commentOpen} onHide={commentsClose} className="viewphoto-popup" style={{ paddingLeft: 0 }}>
                <Modal.Header className="show-only-mobile">
                    <div className="model-header-wid">
                        <div className="model-header-popup">
                            <div className="model-popup-back">
                                <MdKeyboardArrowLeft onClick={commentsClose} size={30} />
                            </div>
                            <p className="posttitle">Post</p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <PostPop item={item} />
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
export { PostFooter };