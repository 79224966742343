import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { Shortlist } from "../ShortList/ShortList";


const ActionsDp = ({ item }) => {
    console.log(item)

    return (
        <>
            <dpostiv className="actionsdp-list">
                <a className="btn btn-default d-flex" style={{ alignItems: 'center', gap: '5px' }}>
                    <b>{item.views ? item.views : 0}</b> views
                </a>
                <Shortlist ShortlistId={item.id} />
            </dpostiv>
        </>
    )
}
export { ActionsDp };