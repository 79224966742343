import React, { useState, useEffect } from "react"
import "./AdRight.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import {AiOutlineCalendar,AiOutlineComment,AiOutlineSend,AiOutlineStar,AiOutlineHeart} from "react-icons/ai";
import {MdSupervisorAccount,MdOutlineTouchApp} from "react-icons/md";

const AdRight = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true
    };


    const [Data, SetData] = useState([])

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/masters/right-ads/?adpost_status=active")
            console.log(responce)
            SetData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
    }, [])

    // const Navigate = useNavigate()
    // const handleClick = (event) => {
    //     event.preventDefault();
    //     // Navigate(`/catlog/sub-categories/${Data.subcategory}`)
    //     console.log(event)
    // }


    return (
        <>
            <div className="adpost-container">
                <div className="container">
                    <Slider {...settings}>
                        {Data.map((bannerItem) => {
                            return (
                                <a href={bannerItem.url} target="_blank">
                                    <div className="adpost-item" key={bannerItem.id}>
                                        <div className="adpost-img">
                                            <img src={bannerItem.poster} />
                                            <div className="post-footer">
                                                <p>Sponsored</p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                        })}


                    </Slider>
                </div>
            </div>
        </>
    )
}
export { AdRight };