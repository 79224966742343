import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { Footer, Navbar, DeletePopupModal, FormParser } from "../Components";
import { useNavigate, useSearchParams, NavLink, useParams } from "react-router-dom";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { Modal } from "react-bootstrap";
import Masters from "../Components/masters.json"
import { AlertDeletePopupModal } from "../Components/PopupModal";
// import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";

const AuditionRequirements = () => {
    let { id } = useParams();
    console.log(id)
    const [requirementShow, setRequirementShow] = useState(false)
    const [requirementData, setRequirementData] = useState({})
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Data, setData] = useState({})
    const GetRequirementdata = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${id}`)
            console.log(responce)
            setRequirementData(responce.data.results);
        }
        catch (error) {
            console.log(error)
        }
    }
    const AudiRequirements = (data) => {
        console.log(Data)
        const orderTopost = {
            ...data,
            audition: id
        }
        console.log(orderTopost)
        if (Data.id) {
            axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, orderTopost).then(res => {
                console.log(res)
                GetRequirementdata()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
                // setpopupType("error");
            })
        } else {
            axiosConfig.post("/accounts/audition-requirements/", orderTopost).then(res => {
                console.log(res)
                GetRequirementdata()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
                // setpopupType("error");
            })
        }
    }
    const HandleEdit = (id) => {
        setData({})
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            setData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = async (id) => {
        await axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(() => {
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")
            GetRequirementdata();
        }).catch((error) => {
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
            console.log(error)
        })
    }
    useEffect(() => {
        GetRequirementdata()
    }, [])
    return (
        <>
            <div className="Audi-require" onClick={() => { setRequirementShow(true); setData({}) }}>+Add Requirements</div >
            {requirementShow &&
                <Modal
                    size="lg"
                    show={requirementShow}
                    onHide={() => setRequirementShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Add Audition Requirements
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} />
                    </Modal.Body>
                </Modal>
            }
            <div className="customtable-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr</th>
                            <th>Role Name</th>
                            <th>Character</th>
                            <th>Specification</th>
                            <th>Requirements</th>
                            <th>Age/Gender</th>
                            <th>Languages</th>
                            <th>State</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {Object.keys(requirementData).length !== 0 && (
                        <tbody>
                            {
                                requirementData.map((frame, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{frame.role}</td>
                                            <td>{frame.character}</td>
                                            <td>{frame.specification}</td>
                                            <td>{frame.no_of_requirements}</td>
                                            <td>{frame.from_age}-{frame.to_age}/{frame.gender}</td>
                                            <td>{frame?.languages?.join(', ')}</td>
                                            <td>{frame.state?.join(', ')}</td>
                                            <td>
                                                <div className="d-flex justify-content-evenly">
                                                    <MdOutlineEdit onClick={() => { HandleEdit(frame.id) }} />
                                                    <MdOutlineDelete onClick={() => DeleteAlert(frame.id)} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    )}
                </table>
            </div>
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
        </>
    )
}
export { AuditionRequirements };