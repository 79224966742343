import React, { useState, useEffect } from "react";
import "./Navbar.css"
import axiosConfig from "../../Service/axiosConfig"
import { useSelector } from "react-redux";
import { selectCartTotal } from "../../redux/Slices/cartSlice";
import { MdOutlineHome, MdOutlineExplore, MdChatBubbleOutline, MdOutlineNotifications, MdOutlineAddToPhotos, MdOutlineMovieCreation, MdOutlineAccountCircle, MdOutlineVerified } from 'react-icons/md';
import { LiaUserCheckSolid } from "react-icons/lia";
import { AiOutlineStar } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import { ViewCart } from "./ViewCart"
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useAuth } from '../../Utils/AuthContext';

const Navbar = ({ search }) => {
    const { isLoggedIn, is24Craft } = useAuth();
    const Navigate = useNavigate();
    const { cart } = useSelector((state) => state);
    const total = useSelector(selectCartTotal);
    const [show, setShow] = useState(false);
    const [LoggedIn, setLoggedIn] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [query, setQuery] = useState(search);
    const handleLoginClose = () => setloginShow(false);
    const [ActiveUrl, setActiveUrl] = useState("")
    const [NoAccess, setNoAccess] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    function handleInputChange(event) {
        setQuery(event.target.value);
    }
    const ActiveData = (activeUrl) => {
        localStorage.setItem("activeurl", activeUrl)
    }
    const GetlocaData = () => {
        const Accounturl = localStorage.getItem("activeurl")
        setActiveUrl(Accounturl)
    }
    function handleSubmit(event) {
        event.preventDefault();
        Navigate(`/product-search?query=${query}`)
    }

    function handleLoginEvent() {
        handleLoginClose(false);
        setLoggedIn(true)
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setLoggedIn(true)
        }
    }
    useEffect(() => {
        CheckLogin();
        GetlocaData();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    const MobileLogin = () => {
        Navigate("/account")
    }
    return (
        <>

            <div className="main-menu">
                <div className="menu-container">
                    <div className="menu-item">
                        <NavLink to="/">
                            <MdOutlineHome className="menu-icon" />
                            <p className="sidebar-title">Home</p>
                        </NavLink>
                    </div>
                    <div className="menu-item">
                        <NavLink to="/s">
                            <MdOutlineExplore className="menu-icon" />
                            <p className="sidebar-title">Search</p>
                        </NavLink>
                    </div>
                    <div className="menu-item">
                        <NavLink to="/auditions">
                            <MdOutlineMovieCreation className="menu-icon" />
                            <p className="sidebar-title">Auditions</p>
                        </NavLink>
                    </div>
                    {isLoggedIn &&
                        <>
                            <div className="menu-item">
                                <NavLink to="/shortlist">
                                    <LiaUserCheckSolid className="menu-icon" />
                                    <p className="sidebar-title">Shortlist</p>
                                </NavLink>
                            </div>
                            {is24Craft &&
                                <>
                                    {/* <div className="menu-item">
                                        <NavLink to="/verified">
                                            <MdOutlineVerified className="menu-icon" />
                                            <p className="sidebar-title">Verified Profile</p>
                                        </NavLink>
                                    </div> */}
                                </>
                            }
                            {/* <div className="menu-item">
                                <NavLink to="/24crafts">
                                    <MdOutlineMovieCreation className="menu-icon" />
                                    <p className="sidebar-title">Create Profile</p>
                                </NavLink>
                            </div> */}
                            <div className="menu-item">
                                <NavLink to="/my-profile">
                                    <MdOutlineAccountCircle className="menu-icon" />
                                    <p className="sidebar-title">Profile</p>
                                </NavLink>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export { Navbar };