import React, { useState, useEffect } from "react"
import "./AdPost.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import {AiOutlineCalendar,AiOutlineComment,AiOutlineSend,AiOutlineStar,AiOutlineHeart} from "react-icons/ai";
import {MdSupervisorAccount,MdOutlineTouchApp} from "react-icons/md";

const AdPost = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true
    };


    const [Data, SetData] = useState([])

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/accounts/ad-posts/?adpost_status=active")
            console.log(responce)
            SetData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
    }, [])

    // const Navigate = useNavigate()
    // const handleClick = (event) => {
    //     event.preventDefault();
    //     // Navigate(`/catlog/sub-categories/${Data.subcategory}`)
    //     console.log(event)
    // }


    return (
        <>
            <div className="adpost-container">
                <div className="container">
                    <Slider {...settings}>
                        {Data.map((bannerItem) => {
                            return (
                                <div className="adpost-item" key={bannerItem.id}>
                                    <div className="adpost-img">
                                        <img src={bannerItem.poster} />
                                        <p><AiOutlineCalendar  className="ico"/> {bannerItem.active_to}</p>
                                        <div className="row">
                                            <div className="col-6">
                                                <p>{bannerItem.title}</p>
                                            </div>
                                            <div className="col-6">
                                                <p><MdSupervisorAccount />  {bannerItem.required_artists}</p>
                                            </div>
                                            <div className="col-12">
                                            </div>
                                        </div>
                                        <div className="post-footer">
                                            <div className="symbols">
                                                <AiOutlineHeart className="post-symbol-icons" />
                                                <AiOutlineSend className="post-symbol-icons" />
                                            </div>
                                            <div className="shortlist applynow">
                                                <button className="btn btn-primary" ><MdOutlineTouchApp className="post-symbol-icons" />Apply Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                    </Slider>
                </div>
            </div>
        </>
    )
}
export { AdPost };