import React from "react";
import "../Components/Navbar/Navbar.css"
import { PostDP } from "../Components";

const ExploreGrid = ({ Data, loading, loading2 }) => {
    return (
        <>
            <div className="products-container">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <>
                        {Data.length > 0 ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        {
                                            Data.map((itemObj, index) => {
                                                return (
                                                    <div className="col-md-4" key={index}>
                                                        <div className="search-container">
                                                            <PostDP item={itemObj} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {loading2 && (
                                        <div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                        </div>
                                    )}
                                </div>
                            </>
                            :
                            <>
                                <p className="nosearchresults">Search with new keyword/ filters</p>
                            </>
                        }
                    </>
                )}
            </div>
        </>
    )
}
export { ExploreGrid };