import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Actions } from "./Actions";
import { Helmet } from "react-helmet";
import { PostFooter } from "./PostFooter";
import { AiOutlineComment, AiOutlineHeart, AiOutlineSend } from "react-icons/ai";
import { Like } from "../Like";
import { Shortlist } from "../ShortList";
import { LikesPopup } from "./LikesPop";
import { CommentLike } from "../../Pages";

const PostPop = (props) => {
    const { item } = props;
    console.log("pitem")
    console.log(item, "pitem")
    function handleHideEvent() {
        props.handleHideEvent();
    }
    const [likesCount, SetlikesCount] = useState(item.likes)
    const [smShow, setSmShow] = useState(false);
    const [chatMessage, setChatMessage] = useState("");
    const [Messages, SetMessages] = useState([]);
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1);
    const [parentMessage, setParentMessage] = useState(null);
    function updateLikesCount(action) {
        console.log("sss", action, likesCount)
        if (action === "like") {
            SetlikesCount(likesCount + 1)
        } else {
            SetlikesCount(likesCount - 1)
        }
    }
    const shareContent = async (share_to) => {
        try {
            let text = `${window.location.origin}/p/${item.uid}`
            const shareUrl = encodeURIComponent(text);

            if (share_to == "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to == "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    // Use Web Share API if available
                    await navigator.share({
                        title: 'Share Post',
                        text: `${window.location.origin}/p/${item.uid}`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const OpenPopUp = () => {
        setSmShow(true)
    }
    const calculateTime = (endDate) => {
        const end = new Date(endDate);
        const now = new Date();
        const difference = now - end;
        const units = [
            { label: 'd', value: 24 * 60 * 60 * 1000 },
            { label: 'h', value: 60 * 60 * 1000 },
            { label: 'm', value: 60 * 1000 },
            { label: 's', value: 1000 }
        ];
        for (const unit of units) {
            const diffInUnit = Math.floor(difference / unit.value);
            if (diffInUnit > 0) {
                return `${diffInUnit}${unit.label}`;
            }
        }
        return 'just now';
    };
    const GetComments = async () => {
        console.log(page)
        try {
            const response = await axiosConfig.get(`/accounts/comments/?modelimage=${item.id}&page_size=20&page=${page}`)
            setCount(Math.ceil(response.data.count / 20))
            if (page === 1) {
                SetMessages(response.data.results);
            } else {
                SetMessages([...Messages, ...response.data.results])
            }
        }
        catch (error) {
            console.log(error)
        }
    };
    const handleScroll = (e) => {
        const { clientHeight, scrollTop, scrollHeight } = e.target
        console.log(clientHeight, scrollTop, scrollHeight)
        const tolerance = 5;
        if (clientHeight + scrollTop >= scrollHeight - tolerance && count > page) {
            setPage(prevPage => prevPage + 1);
        }
    }
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const handleReply = (message) => {
        console.log(message)
        setParentMessage(message);
        setChatMessage(`@${message.user.first_name} `);
    };
    const profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const handleSendMessage = () => {
        console.log(chatMessage, "messagess")
        const data_to_post = {
            content: chatMessage,
            modelimage: item.id,
            user: profile_data?.id,
            parent: parentMessage ? parentMessage.id : null,
        }
        if (localStorage.getItem('token')) {
            axiosConfig.post(`/accounts/comments/`, data_to_post)
                .then(response => {
                    console.log(response)
                    GetComments()
                    setParentMessage(null);
                    setChatMessage("")
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            handleLoginShow();
        }
    };
    useEffect(() => {
        GetComments()
    }, [page])
    return (
        <>
            <Helmet>
                <meta property="og:image" content={item.image} />
                <meta property="og:title" content={item.profile.full_name} />
                <meta property="og:description" content={`${item.profile.hcc_id} - ${item.profile.designation}`} />
            </Helmet>
            <div className="post-modal-view">
                <diV className="pm-img-container">
                    <img src={item.image} className="post-modal-img" />
                    <div className="show-only-mobile mobile-pop-foot">
                        <div className="post-footer-container">
                            <div className="post-footer">
                                <div className="symbols">
                                    <Like productId={item.id} updateLikesCount={updateLikesCount} />
                                    <AiOutlineComment className="post-symbol-icons" />
                                    <AiOutlineSend className="post-symbol-icons" onClick={() => shareContent("share")} />
                                </div>
                                <div className="shortlist">
                                    <Shortlist ShortlistId={item.profile.id} />
                                </div>
                            </div>
                            <div className="post-likes" onClick={OpenPopUp}>
                                {likesCount > 0 && <p>{likesCount} {likesCount === 1 ? 'like' : 'likes'}</p>}
                            </div>
                            <div className="post-caption">
                                <p><strong>{item.profile.full_name} : </strong> {item.caption}</p>
                            </div>
                            <div className="post-comments">
                            </div>
                            <div className="post-new-comment">
                                <input type="text"
                                    className="form-control msg-text"
                                    value={chatMessage}
                                    onChange={(e) => setChatMessage(e.target.value)}
                                    onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                                    placeholder="Type a message..."
                                />
                                {chatMessage &&
                                    <div className="send-button" onClick={handleSendMessage}>
                                        Send
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </diV>
                <div className="pm-content-container">
                    <div className="pdpop-header">
                        <div className="pdpop-ls">
                            <div className="pdpop-ls-container">
                                <img src={item.profile.profile_pic} className="post-dp" width="40" />
                                <div className="pdpop-titles">
                                    <a href={`/profile/${item.profile.hcc_id}`} style={{ textDecoration: 'none', color: 'rgb(33 37 41)' }}><p style={{ fontWeight: 600, fontSize: '14px' }}>{item.profile.full_name}</p></a>
                                    <p><strong>{item.profile.hcc_id}</strong> - {item.profile.designation}</p>
                                </div>
                            </div>
                        </div>
                        <div className="pdpop-rs">
                            <Actions item={item} handleHideEvent={handleHideEvent} />
                        </div>
                    </div>
                    <hr className="pdpop-hr" />
                    <div className="hide-mobile">
                        <div className="open-comments-desktop" onScroll={(e) => handleScroll(e)}>
                            {Messages && Messages.map((message, index) => (
                                <div className="d-flex comment-list" key={index}>
                                    <div className="d-flex" style={{ gap: "10px" }}>
                                        <div className="">
                                            <img src={message?.user?.profile_pic ? message?.user?.profile_pic : message?.user?.profile_pic} className="comment-profile" />
                                        </div>
                                        <div className="comment-person">
                                            <div className="comment-detail">
                                                {message?.user?.first_name} <span>{message.content}</span>
                                            </div>
                                            <div className="reach">
                                                <span>{message.created_at && calculateTime(message.created_at)}</span>
                                                {/* {likesCount > 0 && <p>{likesCount} {likesCount === 1 ? 'like' : 'likes'}</p>} */}
                                                <span>likes</span>
                                                <span onClick={() => handleReply(message)}>Reply</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span>
                                        <CommentLike productId={message.id} />
                                    </span>
                                </div>
                            ))}
                        </div>
                        <hr className="pdpop-hr" />
                        <div className="post-footer-container-desktop">
                            <div className="post-footer-container">
                                <div className="post-footer">
                                    <div className="symbols">
                                        <Like productId={item.id} updateLikesCount={updateLikesCount} />
                                        <AiOutlineComment className="post-symbol-icons" />
                                        <AiOutlineSend className="post-symbol-icons" onClick={() => shareContent("share")} />
                                    </div>
                                    <div className="shortlist">
                                        <Shortlist ShortlistId={item.profile.id} />
                                    </div>
                                </div>
                                <div className="post-likes" onClick={OpenPopUp}>
                                    {likesCount > 0 && <p>{likesCount} {likesCount === 1 ? 'like' : 'likes'}</p>}
                                </div>
                                <div className="post-caption">
                                    <p><strong>{item.profile.full_name} : </strong> {item.caption}</p>
                                </div>
                                <div className="post-comments">
                                </div>
                                <div className="post-new-comment">
                                    <input type="text"
                                        className="form-control msg-text"
                                        value={chatMessage}
                                        onChange={(e) => setChatMessage(e.target.value)}
                                        onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                                        placeholder="Type a message..."
                                    />
                                    {chatMessage &&
                                        <div className="send-button" onClick={handleSendMessage}>
                                            Send
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {smShow === true &&
                <LikesPopup item={item} smShow={smShow} setSmShow={setSmShow} />
            }
        </>
    )
}
export { PostPop };