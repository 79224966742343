import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { useNavigate } from 'react-router-dom';
import { PostGrid } from "../Components";
import Masonry from 'react-masonry-css'
import { MdKeyboardArrowLeft, MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { PostPop } from "../Components/Post/PostPop";
import { useSwipeable } from 'react-swipeable';
import { NewPost } from "./NewPost";

const PostsPageGrid = (props) => {
    const [photoShow, setphotoShow] = useState(false);
    const [item, setItem] = useState();
    const [popUpIdx, setpopUpIdx] = useState();
    console.log(props)
    let { profile_id, can_edit_profile } = props;
    console.log(profile_id)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [Data, SetData] = useState([])
    const [AddPost, setAddPost] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    let page = 1;
    let totalPages;

    const GetData = async () => {
        setLoading(true)
        try {
            console.log('profile_id', profile_id)
            const responce = await axiosConfig.get(`/accounts/model-images/?profile=${profile_id}&is_active=true`)
            totalPages = Math.ceil(responce.data.count / 20);
            console.log(totalPages)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleInfiniteScroll = async () => {

        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 500 >=
                document.documentElement.scrollHeight
            ) {
                if (page < totalPages) {
                    setLoading2(true)
                    page = page + 1
                    let resp = await axiosConfig.get(`/accounts/model-images/?page=${page}&profile=${profile_id}&is_active=true`)
                    SetData((Data) => [...Data, ...resp.data.results])
                    setLoading2(false)
                } else {
                    setLoading2(false)
                }

            }

        } catch (error) {
            console.log(error)
        }
    }

    function handleHidePostEvent() {
        GetData();
        setphotoShow(false)
    }

    const handlers = useSwipeable({
        onSwipedUp: (eventData) => { console.log("swipeup event", eventData); handleClickEvent("next"); },
        onSwipedDown: (eventData) => { console.log("swipeup down", eventData); handleClickEvent("prev") },
        onSwipedLeft: (eventData) => { console.log("swipeup event", eventData); handleClickEvent("next"); },
        onSwipedRight: (eventData) => { console.log("swipeup down", eventData); handleClickEvent("prev") },
        // ...config,
        delta: 100,
        preventScrollOnSwipe: true
    });
    function handleClickEvent(clickevent) {
        console.log(clickevent)
        if (clickevent == "next") {
            let newitem = Data[popUpIdx + 1]
            if (newitem) {
                setItem(newitem)
                setpopUpIdx(popUpIdx + 1)
            }
        } else {
            let newitem = Data[popUpIdx - 1]
            if (newitem) {
                setItem(newitem)
                setpopUpIdx(popUpIdx - 1)
            }
        }
        console.log(Data.length)
    }

    useEffect(() => {
        GetData();
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [])
    const breakpointColumnsObj = {
        default: 3,  // Default is desktop with 3 columns
        767.98: 2      // For screen sizes up to 769px, 2 columns
    };
    return (
        <>
            {
                <div className="products-container">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('./images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <div className="container">
                            {can_edit_profile &&
                                <div className="flri">
                                    <button className="btn btn-default mb-2" onClick={(e) => { setAddPost(true); }}>Add New Photos</button>
                                </div>
                            }
                            {Data.length > 0 &&
                                <div className="post-data post-grid">
                                    <Masonry
                                        breakpointCols={breakpointColumnsObj}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column">
                                        {
                                            Data.map((itemObj, index) => {
                                                return (
                                                    <div className="post-item" key={index}>
                                                        <div className="post-inner-container">
                                                            <div className="post-image" onClick={() => { setphotoShow(true); setItem(itemObj); setpopUpIdx(index) }}  >
                                                                <img src={itemObj.image} className="post-img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Masonry>


                                    {loading2 && (
                                        <div className="loader-container">
                                            <img src={require('./images/loader.gif')} alt="Loading..." />
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    )}
                </div>
            }
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={photoShow} onHide={() => setphotoShow(false)} className="viewphoto-popup" style={{ paddingLeft: 0 }}>
                <Modal.Header className="show-only-mobile">
                    <div className="model-header-wid">
                        <div className="model-header-popup">
                            <div className="model-popup-back">
                                <MdKeyboardArrowLeft onClick={() => setphotoShow(false)} size={30} />
                            </div>
                            <p className="posttitle">Post</p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div{...handlers}>
                        <PostPop item={item} handleHideEvent={handleHidePostEvent} />
                        <div className="navigation-buttons">
                            <MdOutlineNavigateBefore className="nav-prev" onClick={() => handleClickEvent("prev")} />
                            <MdOutlineNavigateNext className="nav-next" onClick={() => handleClickEvent("next")} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
        </>
    )
}
export { PostsPageGrid };