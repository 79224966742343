import React, { useState, useEffect } from "react";
import "./CustomTop.css";
import { useLocation, NavLink } from "react-router-dom";
import { LuMessageSquare } from "react-icons/lu";
import { BiAddToQueue, BiMenuAltLeft, BiSearch, BiSearchAlt2 } from "react-icons/bi";
import { MdOutlineNotifications, MdOutlinePowerSettingsNew, MdOutlinePassword, MdOutlineSettings } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";
import { useAuth } from "../Utils/AuthContext";
import { useNavigate } from "react-router";
import Modal from 'react-bootstrap/Modal';
import { Login, Signup } from "../Components/Login";
import CustomMobile from "./CustomMobile";
import { LiaUserCheckSolid } from "react-icons/lia";
import { Notifications } from "./Notifications";
import { NewPost } from "./NewPost";

const CustomTop = () => {
    const location = useLocation();
    const Navigate = useNavigate();
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const [show, setShow] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [ProfileData, setProfileData] = useState({})
    const [isRead, setIsRead] = useState(false);
    const [page, setPage] = useState(1);
    const [AddPost, setAddPost] = useState(false)
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }
    const MobileLogin = () => {
        Navigate("/my-profile")
    }
    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        setProfile()
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true)
            setProfile()
        }
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    useEffect(() => {
        setProfile();
        CheckLogin();
        if (location.pathname === '/signup') {
            if (localStorage.getItem("token")) {
                Navigate("/")
                setloginView("")
                setloginShow(false)
            } else {
                console.log("test")
                setloginView("signup")
                // setloginShow(true)
            }
        }
    }, [])
    const handleClose = () => { setShow(false); setIsRead(false) }
    const handleShow = () => { setShow(true); setPage(1) }
    return (
        <>
            <div className="desktop-container">
                <div className="top-main">
                    <div className="logo" onClick={() => Navigate('/')}>
                        <img src={require('../Pages/images/HCC (10).png')} className="logo-image" />
                    </div>
                    <div className="search" onClick={() => Navigate('/s')}>
                        <input
                            type="text"
                            placeholder="Search for Artists..."
                        />
                        <BiSearch className="option-icon" />
                    </div>
                    {isLoggedIn ?
                        <div className="options">
                            <div className="options-container">
                                <div onClick={() => Navigate('/')}><LuMessageSquare className="option-icon" /></div>
                                <div onClick={handleShow}><MdOutlineNotifications className="option-icon" /></div>
                                {is24Craft &&
                                    <>
                                        {/* <div onClick={() => Navigate('/new-post')}><BiAddToQueue className="option-icon" /></div> */}
                                        <div onClick={(e) => { setAddPost(true); }}><BiAddToQueue className="option-icon" /></div>
                                    </>
                                }
                                {/* <RiAccountCircleLine className="option-icon" /> */}
                                <div className="nav-item dropdown account-topnav">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={ProfileData && ProfileData.profile_pic} width="40" className="profile-pic-top" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to="/my-profile" >My Account</NavLink>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to="/my-profile" >Change Password</NavLink>
                                            </a>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><NavLink to="/logout" className="dropdown-item" onClick={() => ActiveData("/logout")}>Log Out</NavLink></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="options">
                            <div className="login-text" onClick={handleLoginShow}>login</div>
                        </div>
                    }

                </div>
                {/* <div className="content">
                    {children}
                </div> */}
            </div>
            <div className="top-mobile-main">
                <div className="logo">
                    {isLoggedIn &&
                        <div class="dropdown show-only-mobile">
                            <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" style={{ border: 'none' }}>
                                <BiMenuAltLeft className="option-icon" />
                            </button>
                            <ul class="dropdown-menu dropdown-margin popup-sheet">
                                < li >
                                    {/* <a class="dropdown-item" href="#"><RiAccountCircleLine /> My Profile</a> */}
                                    < NavLink to="/my-profile" className="dropdown-item" > <RiAccountCircleLine /> My Profile</NavLink>
                                </li>
                                <li>
                                    < NavLink to="/shortlist" className="dropdown-item" > <LiaUserCheckSolid /> Shortlisted Profiles</NavLink>
                                    {/* <a class="dropdown-item" href="#"><LiaUserCheckSolid /> Shortlisted Profiles</a> */}
                                </li>
                                {/* <li>
                                    <a class="dropdown-item" href="#"><TiTick color="#0d6efd" /> Verify Your Profile</a>
                                </li> */}
                                {/* <li>
                                    <a class="dropdown-item" href="#"><LiaUserCheckSolid /> Following</a>
                                </li> */}
                                <li>
                                    <a class="dropdown-item" href="#"><MdOutlineSettings /> Setting</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#"><MdOutlinePassword /> Change Password</a>
                                </li>
                                <li>
                                    <NavLink to="/logout" className="dropdown-item" ><MdOutlinePowerSettingsNew /> Log Out</NavLink>
                                </li>
                                <li className="follow-us">
                                    <hr />
                                    <a class="dropdown-item" href="#" style={{ textAlign: 'center' }}>Follow Us On</a>
                                    <div className="social-align">
                                        <img src={require('../Pages/images/facebook.jpg')} className="social-icons" />
                                        <img src={require('../Pages/images/instagram.jpg')} className="social-icons" />
                                        <img src={require('../Pages/images/youtube1.jpg')} className="social-icons" />
                                        <img src={require('../Pages/images/twitter.png')} className="social-icons" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    }
                    <img src={require('../Pages/images/HCC (10).png')} className="logo-image" />
                    <div className="d-flex" style={{ gap: '20px ' }}>
                        <NavLink to="/s" >
                            <BiSearchAlt2 className="option-icon top-search-icon" />
                        </NavLink>
                        <div onClick={isLoggedIn ? MobileLogin : handleLoginShow}>
                            {!isLoggedIn && <a href="#" style={{ color: '#000', padding: '2px', border: '1px solid #fff', fontWeight: '700' }} >Login</a>}
                        </div>
                    </div>
                </div>
                <CustomMobile />
            </div>
            <Notifications show={show} setShow={setShow} handleClose={handleClose} ProfileData={ProfileData} isRead={isRead} setIsRead={setIsRead} setPage={setPage} page={page} />
            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton>
                    <Modal.Title>{loginView == "login" ? "Login" : "Signup"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loginView == "login" ? (
                        <>
                            <Login handleLoginEvent={handleLoginEvent} />
                            <a href="#" className="switchtag" onClick={() => setloginView("signup")} >Don't have an account ? Click to signup</a>
                        </>
                    ) : (
                        <>
                            <Signup handleLoginEvent={handleLoginEvent} />
                            <a href="#" className="switchtag" onClick={() => setloginView("login")} >Already have an account ? Click to Login</a>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="loginfooter">
                    <p>By continuing, you agree to our</p>
                    <a href="#" target="_blank">Terms of service</a>
                    <a href="#" target="_blank">Privacy policy</a>
                </Modal.Footer>
            </Modal>
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
        </>
    )
}

export default CustomTop;