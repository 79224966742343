import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";
import { AiOutlineComment, AiOutlineSend, AiOutlineStar } from "react-icons/ai";
import { LiaUserCheckSolid } from "react-icons/lia";
import { Like } from "../Like"
import { Actions } from "./Actions";
import { PostFooter } from "./PostFooter";
import { Follow } from "../Follow";
import { BiSolidBadgeCheck } from "react-icons/bi";


const Post = (props) => {

    const { item } = props;
    const [loading, setLoading] = useState(false)
    const [likesCount, SetlikesCount] = useState(item.likes)
    const [isHidden, setIsHidden] = useState(false);

    function updateLikesCount(action) {
        console.log("sss", action, likesCount)
        if (action === "like") {
            SetlikesCount(likesCount + 1)
        } else {
            SetlikesCount(likesCount - 1)
        }
    }
    console.log(item)
    function handleHideEvent() {
        setIsHidden(true);
    }
    if (isHidden) {
        return null;
    }


    const shareContent = async (share_to) => {
        try {
            let text = `${window.location.origin}/p/${item.uid}`
            const shareUrl = encodeURIComponent(text);

            if (share_to == "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to == "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `${window.location.origin}/p/${item.uid}`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };

    return (
        <>
            <div className="post-inner-container post-shadow">
                <div className="postheads" style={{ alignItems: 'center' }}>
                    <div className="profile-pic-divs">
                        <NavLink target="_blank" to={`/profile/${item.profile.hcc_id}`}><img src={item.profile.profile_pic} className="profile-pic" /></NavLink>
                    </div>
                    <div className="profile-titles profile-follow">
                        <div>
                            <p><NavLink target="_blank" to={`/profile/${item.profile.hcc_id}`}>{item.profile.full_name}</NavLink>{item.profile.is_verified && <BiSolidBadgeCheck size={20}/>}</p>
                            <p>{item.profile.hcc_id} - {item.profile.designation}</p>
                        </div>
                        <Follow FollowId={item.profile.id} />
                    </div>
                    <p className="profile-actions">
                        <Actions item={item} handleHideEvent={handleHideEvent} />
                    </p>
                </div>
                <div className="post-image">
                    <img src={item.image} className="post-img" />
                </div>
                <PostFooter item={item} />
            </div >
        </>
    )
}
export { Post };