import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { useNavigate } from 'react-router-dom';
import {Post} from "../Components";

const PostsPage = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [Data, SetData] = useState([])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };

    let page = 1;
    let totalPages;
    let endpoint = '/accounts/model-images/?is_story=false&is_active=true'
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`${endpoint}`)
            totalPages = Math.ceil(responce.data.count / 20);
            console.log(totalPages)
            SetData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                if (page <= totalPages && !loading2) {
                    console.log(page);
                    console.log("inside infinite scroll...");
    
                    setLoading2(true); // Set loading2 to true to prevent multiple triggers
    
                    page = page + 1;
                    let resp = await axiosConfig.get(`${endpoint}&page=${page}`)
                    console.log(resp);
                    SetData((Data) => [...Data, ...resp.data.results]);
                    setLoading2(false); // Set loading2 to false when the new data is loaded
                }
            }
        } catch (error) {
            console.log(error);
            setLoading2(false); // Set loading2 to false in case of an error
        }
    };

    useEffect(() => {
        GetData();
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return (
        <>
            {
                    <div className="products-container">
                        {loading ? (
                            <div className="loader-container">
                                <img src={require('./images/loader.gif')} alt="Loading..." />
                            </div>
                        ) : (
                            <>
                                {Data.length > 0 &&
                                    <div className="post-data">
                                        {
                                            Data.map((itemObj, index) => {
                                                return (
                                                    <div className="post-item" key={index}>
                                                        <Post item={itemObj} index={index} />
                                                    </div>
                                                )
                                            })
                                        }
                                        {loading2 && (
                                            <div className="loader-container">
                                                <img src={require('./images/loader.gif')} alt="Loading..." />
                                            </div>
                                        )}
                                    </div>
                                }
                            </>
                        )}
                    </div>
            }
        </>
    )
}
export { PostsPage };